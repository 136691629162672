import './style.css'
import * as THREE from 'three'
import gsap from 'gsap'
import {OrbitControls} from 'three/examples/jsm/Addons.js'
import GUI from 'lil-gui'
import {FontLoader} from 'three/examples/jsm/Addons.js'
import {TextGeometry} from 'three/examples/jsm/Addons.js'


// const loadingManager = new THREE.LoadingManager()
// loadingManager.onStart = () =>
// {
//     console.log('onStart')
// }
// loadingManager.onLoad = () =>
//     {
//         console.log('onLoad')
//     }
// loadingManager.onProgress = () =>
//         {
//             console.log('onProgress')
//         }
// loadingManager.onError = () =>
//             {
//                 console.log('onError')
//             }

//Fonts
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
 (font) =>
    {
            const textGeometry = new TextGeometry(
                'PROIRON',
                {
                    font: font,
                    bevelEnabled: false,
                    size: 1,
                    height: 0.2,
                    curveSegments: 20,
                    bevelEnabled: true,
                    bevelThickness: 0.05,
                    bevelSize: 0.05,
                    bevelOffset: 0,
                    bevelSegments: 10,
                
                }
            )
            const textMaterial = new THREE.MeshStandardMaterial()
            
            textMaterial.roughness = 0.1
            textMaterial.metalness = 0.1
            textGeometry.center()


            const text = new THREE.Mesh(textGeometry,textMaterial)
            scene.add(text)

        for(let i= 0; i < 100; i++)
            {
                const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
                const donut = new THREE.Mesh(donutGeometry,textMaterial)

                donut.position.x = (Math.random() - 0.5) * 10
                donut.position.y = (Math.random() - 0.5) * 10
                donut.position.z = (Math.random() - 0.5) * 10

                donut.rotation.x = Math.random() * Math.PI
                donut.rotation.y = Math.random() * Math.PI

                const scale = Math.random()
                donut.scale.set(scale,scale,scale)

                scene.add(donut)

            }
    }
)


// // Textture
// const textureLoader = new THREE.TextureLoader(loadingManager)
// const boxtextureLoader = new THREE.CubeTextureLoader()

// const basecolor = textureLoader.load('/resource/picture/basecolor.jpg')
// const opacity = textureLoader.load('/resource/picture/opacity.jpg')
// const oko = textureLoader.load('/resource/picture/ok.png')
// const ambientOcclusion = textureLoader.load('/resource/picture/ambientOcclusion.jpg')
// const normal = textureLoader.load('/resource/picture/normal.jpg')
// const height = textureLoader.load('/resource/picture/height.png')

// const env = boxtextureLoader.load([
//     '/resource/picture/env/0/px.png',
//     '/resource/picture/env/0/nx.png',
//     '/resource/picture/env/0/py.png',
//     '/resource/picture/env/0/ny.png',
//     '/resource/picture/env/0/pz.png',
//     '/resource/picture/env/0/nz.png'
// ])
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')
// // const basecolor = textureLoader.load('/resource/picture/Metal_Grill_022_basecolor.jpg')

// // colorTexture.repeat.x = 2
// // colorTexture.repeat.y = 2
// // colorTexture.wrapS = THREE.MirroredRepeatWrapping
// // colorTexture.wrapT = THREE.MirroredRepeatWrapping
// // colorTexture.offset.x = 0.5
// // colorTexture.offset.y = 0.5
// // colorTexture.rotation = Math.PI /4
// // colorTexture.center.x = 0.5
// // colorTexture.center.y = 0.5

// // colorTexture.generateMipmaps = false

// // colorTexture.magFilter = THREE.NearestFilter
// // colorTexture.minFilter = THREE.NearestFilter

// //Debug
// const gui = new GUI({
//     width: 200,
//     title: '豆子',
//     closeFolders: false
   

// })

// window.addEventListener('keydown', () =>
// {
//     if(event.key == 'h')
//         gui.show(gui._hidden)
// })

// // gui.close()
// const debugObject = {}




// // // Cursor
// // const cursur = {
// //     x: 0,
// //     y: 0
// // }
// // window.addEventListener('mousemove', (event) => {
// //     cursur.x = event.clientX / sizes.width - 0.5
// //     cursur.y = -(event.clientY / sizes.height -0.5)
// // })





//Canvas
const canvas =document.querySelector('canvas.webgl')



// Scene
const scene = new THREE.Scene()

// Axes helper
const axesHelper = new THREE.AxesHelper(3)
scene.add(axesHelper)





// //Object //////////////////////////////////////////////////////////////////////////////////////////////////

// // const material =  new THREE.MeshBasicMaterial()
// // material.map = basecolor
// // // material.color = new THREE.Color('red')
// // // material.wireframe = true
// // // material.opacity = 0.5
// // material.transparent = true
// // material.alphaMap = opacity
// // material.side = THREE.DoubleSide

// // const material = new THREE.MeshNormalMaterial()
// // material.flatShading = true
// // const material = new THREE.MeshMatcapMaterial()
// // material.matcap = oko

// const material = new THREE.MeshDepthMaterial()
// // const material = new THREE.MeshLambertMaterial()
const material = new THREE.MeshStandardMaterial()
// material.metalness = 0
// material.roughness = 0.4
// material.map = basecolor
// material.aoMap = ambientOcclusion
// material.aoMapIntensity = 0.2
// material.envMap = env
// material.envMapIntensity = 2
// material.alphaMap = opacity
// material.transparent = true
// material.normalMap = normal
// material.normalScale.x = 2
// material.normalScale.y = 2

// material.displacementMap = height
// material.displacementScale = 0.1
// // material.color.set('blue')


// gui.add(material,'metalness').min(0).max(1).step(0.001)
// gui.add(material,'roughness').min(0).max(1).step(0.001)
// gui.add(material,'aoMapIntensity').min(0).max(1).step(0.01)
// gui.add(material,'displacementScale').min(0).max(1).step(0.01)

// // gui.add(material,'normalScale.y').min(0).max(3).step(0.01)

// // material.specular = new THREE.Color(0x1188ff)
// material.side = THREE.DoubleSide

const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 128, 128),
    material
)
sphere.position.x = -1.5
sphere.geometry.setAttribute(
    'uv2',
    new THREE.BufferAttribute(sphere.geometry.attributes.uv.array, 2)
)


// const plane = new THREE.Mesh(
//     new THREE.PlaneGeometry(1,1,100,100),
//     material
// )
// plane.geometry.setAttribute(
//     'uv2',
//     new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2)
// )

// const torus = new THREE.Mesh(
//     new THREE.TorusGeometry(0.3, 0.2, 256, 512),
//     material
// )
// torus.position.x = 1.5
// torus.geometry.setAttribute(
//     'uv2',
//     new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2)
// )
scene.add(sphere)


const ambientLight = new THREE.AmbientLight(0xffffff, 0.3)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 60)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)



// // const book = gui.addFolder('look')
// // // book.close()

// // book
// //    .add(cube1.position,'y')
// //    .min(-3)
// //    .max(3)
// //    .step(0.01)
// //    .name('cube1')

// // book.add(cube1, 'visible')
// // book.add(material, 'wireframe')
// // book.addColor(debugObject, 'color')
// //    .onChange((value) =>
// // {
// //     material.color.set(debugObject.color)
// // })

// // debugObject.sub =2
// // book.add(debugObject, 'sub')
// //    .min(1)
// //    .max(6)
// //    .step(1)
// //    .onFinishChange(() =>
// //     {
// //         cube1.geometry.dispose()
// //         cube1.geometry = new THREE.BoxGeometry(
// //             1, 1, 1, debugObject.sub, debugObject.sub, debugObject.sub
// //         )
// //     })
// // debugObject.ok = () =>
// //     {
// //         gasp.to(cube1.rotation, { y:cube1.rotation.y + Math.PI * 2 })
// //     }
// // book.add(debugObject, 'ok')


    

// // const A = {
// //     ok: 100
// // }
// // gui.add(A,'ok')





// Size
const sizes ={
    width: innerWidth,
    height: innerHeight   
}
window.addEventListener('resize', () =>
{
    //Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width /sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () =>
      {
        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

        if(!fullscreenElement)
        {
            if(canvas.requestFullscreen)
                {
                    canvas.requestFullscreen()
                }
            else if(canvas.webkitRequestFullscreen)
            {
                canvas.webkitRequestFullscreen()
            }
        }
        else
        {
            if(document.exitFullscreen)
                {
                    document.exitFullscreen()
                }
            else if(document.webkitExitFullscreen)
                {
                    document.webkitExitFullscreen()
                }
        }
        }
        )
//Camera
// const aspectRatio = sizes.width / sizes.height
// const camera = new THREE.OrthographicCamera(-1*aspectRatio, 1*aspectRatio, 1, -1, 0.1, 100)
// // const camera = new THREE.PerspectiveCamera(75,sizes.width / sizes.height)
const camera = new THREE.PerspectiveCamera(80,sizes.width / sizes.height,0.1, 200)
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas,scene)
controls.enableDamping = true 



//Renderer
const renderer =new THREE.WebGLRenderer({ canvas:canvas })
renderer.setSize( sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.render(scene,camera)

//Clock
const clock = new THREE.Clock()

// Animations/////////////////////////////////////////////////////////////////////////////////////////
const tick = () =>{
   const elapsedTime = clock.getElapsedTime()

    //Update objects
    // sphere.rotation.y = 0.2 * elapsedTime
    // plane.rotation.y = 0.2 * elapsedTime
    // torus.rotation.y = 0.2 * elapsedTime

    // sphere.rotation.x = 0.3 * elapsedTime
    // plane.rotation.x = 0.3 * elapsedTime
    // torus.rotation.x = 0.3 * elapsedTime


   //Update controls
    controls.update()



    // console.log(deltaTime)
    // Render
    renderer.render(scene,camera)
    window.requestAnimationFrame(tick)
}
tick()